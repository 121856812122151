
import { isEqual } from 'lodash';
import { computed, defineComponent, reactive } from 'vue';
import { useStore } from 'vuex';

import useMessage from '@/hooks/useMessage';
import useValidation from '@/hooks/useValidation';
import { State } from '@/store';

export default defineComponent({
  setup() {
    const store = useStore<State>();
    const { message, setMessage, clearMessage } = useMessage();
    const user = computed(() => ({
      firstName: store.state.auth.user?.firstName,
      lastName: store.state.auth.user?.lastName,
      gender: store.state.auth.user?.gender || 'm',
      email: store.state.auth.user?.email,
      mobilePhone: store.state.auth.user?.mobilePhone,
    }));

    const values = reactive({
      firstName: user.value?.firstName,
      lastName: user.value?.lastName,
      gender: user.value?.gender,
      email: user.value?.email,
      mobilePhone: user.value?.mobilePhone,
    });

    const save = () => {
      clearMessage();
      if (!isEqual(values, user.value)) {
        return store
          .dispatch('auth/updateUser', values)
          .then(setMessage('auth.update-user-success'))
          .catch(setMessage());
      }
    };

    return {
      message,
      values,
      save,
      ...useValidation(),
    };
  },
});

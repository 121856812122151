
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import { cloneDeep } from 'lodash';
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import useFeatures from '@/hooks/useFeatures';
import { ArticleListItem } from '@/services/api/modules/article.types';
import { State } from '@/store';

export default defineComponent({
  props: {
    item: {
      type: Object as () => ArticleListItem,
      required: true,
    },
    articleRouteName: {
      type: String,
      required: true,
    },
    articleIds: {
      type: Array as () => string[],
      required: false,
    },
    articles: {
      type: Array as () => ArticleListItem[],
      required: false,
    },
    resourceId: {
      type: String,
      required: false,
    },
    fullDate: {
      type: Boolean,
      default: false,
    },
    searchContext: {
      type: Boolean,
      default: false,
    },
    showDescription: {
      type: Boolean,
      default: false,
    },
    showImage: {
      type: Boolean,
      default: false,
    },
    showMeta: {
      type: Boolean,
      default: false,
    },
    showSource: {
      type: Boolean,
      default: false,
    },
    hideEventTime: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const router = useRouter();
    const store = useStore<State>();
    const route = useRoute();
    const { t } = useI18n();
    const clipLoading = ref(false);

    const pubDate = computed(
      () => props.item.pubDate && new Date(props.item.pubDate),
    );

    const isClipped = computed(() =>
      store.state.collections.clippings.includes(props.item.id),
    );

    const isRead = computed(() =>
      store.state.collections.read.includes(props.item.id),
    );

    const formattedDate = computed(() => {
      const { fullDate, item } = props;
      const { eventStart = null } = item;
      const dateFormat = fullDate ? 'd LLL yyyy' : 'd LLL';

      if (eventStart) {
        return format(new Date(eventStart), dateFormat, { locale: nl });
      }

      return pubDate.value
        ? format(pubDate.value, dateFormat, { locale: nl })
        : undefined;
    });

    const formattedTime = computed(
      () => pubDate.value && format(pubDate.value, 'HH:mm'),
    );

    const formattedYear = computed(
      () => pubDate.value && format(pubDate.value, 'yyyy'),
    );

    const eventTime = computed(() => {
      const { eventStart, eventEnd } = props.item;

      if (!props.hideEventTime && eventStart && eventEnd) {
        if (eventStart.getTime() === eventEnd.getTime()) {
          const start = format(eventStart, 'HH:mm');
          if (start === '00:00') {
            return false;
          }
          return format(eventStart, 'HH:mm');
        } else if (eventStart?.toDateString() === eventEnd.toDateString()) {
          const start = format(eventStart, 'HH:mm');
          const end = format(eventEnd, 'HH:mm');

          if (start === '00:00' && end === '23:59') {
            return false;
          }

          return [start, end].join(' - ');
        } else {
          return [
            format(eventStart, 'd MMM HH:mm', { locale: nl }),
            format(eventEnd, 'd MMM HH:mm', { locale: nl }),
          ].join(' - ');
        }
      }

      return false;
    });

    const handleClick = async () => {
      await router.push({
        name: props.articleRouteName,
        params: {
          id: route.params.id,
          resourceId: props.resourceId,
          articleId: props.item.id,
        },
        query: props.searchContext
          ? { search: props.searchContext ? '1' : '0' }
          : {},
      });

      history.replaceState(
        {
          ...history.state,
          articleIds: cloneDeep(props.articleIds),
          articles: cloneDeep(props.articles),
        },
        '',
      );
    };

    const handleClip = () => {
      clipLoading.value = true;

      const dispatchName = isClipped.value
        ? 'collections/removeClippings'
        : 'collections/addClippings';

      store
        .dispatch(dispatchName, {
          origin: props.item.sourceId,
          items: [props.item.id],
        })
        .finally(() => {
          clipLoading.value = false;
        });
    };

    return {
      clipLoading,
      isClipped,
      isRead,
      formattedDate,
      formattedTime,
      formattedYear,
      eventTime,
      handleClick,
      handleClip,
      ...useFeatures(),
    };
  },
});

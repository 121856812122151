
import { useElementVisibility } from '@vueuse/core';
import { isUndefined, kebabCase } from 'lodash';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
} from 'vue';
import { useQuery } from 'vue-query';
import { useStore } from 'vuex';

import { CONTEXT_TYPES, RESOURCE_KINDS } from '@/config/enums';
import { NEWS_ARTICLE } from '@/config/routes';
import useSearch from '@/hooks/useSearch';
import useValidation from '@/hooks/useValidation';
import api from '@/services/api';
import { ArticleListItem } from '@/services/api/modules/article.types';
import { Resource, Workspace } from '@/services/api/modules/workspaces';

import LiveAgenda from './LiveAgenda.vue';
import Settings from './Settings.vue';

export default defineComponent({
  components: {
    LiveAgenda,
    Settings,
  },

  props: {
    resource: {
      type: Object as () => Resource,
      required: true,
    },
    workspace: {
      type: Object as () => Workspace,
      required: true,
    },
    draggable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props) {
    const store = useStore();
    const root = ref();
    const allLoaded = ref(false);
    const loadingMore = ref(false);
    const reload = ref(false);
    const items = ref<ArticleListItem[]>([]);

    const anchor = computed(() => kebabCase(props.resource.title));

    const scrollElement = computed(() => {
      return root.value.querySelector('.list-wrapper');
    });

    const viewElement = computed(() => {
      return document.querySelector<HTMLElement>('.news .view');
    });

    const isVisible = useElementVisibility(root, {
      scrollTarget: viewElement,
    });

    const apiModule = computed(() => {
      const query = props.resource.resourceKind === RESOURCE_KINDS.QUERY;
      return query ? api.queries : api.sources;
    });

    const search = useSearch(props.resource.resourceId, apiModule.value);

    const renderItems = computed(() =>
      search.showSearch.value ? search.searchItems.value : items.value,
    );

    const renderItemIds = computed(() =>
      renderItems.value?.map((item) => item.id),
    );

    const fetchColumn = async () => {
      if (!search.showSearch.value) {
        const [firstItem] = items.value;
        const [lastItem] = items.value.slice(-1);
        const toItem = !loadingMore.value ? firstItem?.id : undefined;
        const fromItem = loadingMore.value ? lastItem?.id : undefined;

        const data = await apiModule.value.items(props.resource.resourceId, {
          count: 30,
          highlight: props.resource.settings?.showSnippet,
          toItem: !reload.value ? toItem : undefined,
          fromItem: !reload.value ? fromItem : undefined,
        });

        const hasRead = data.read.length;
        const finishedLoading = !data.hasMore;

        if (hasRead) store.commit('collections/addRead', data.read);

        if (reload.value) {
          if (finishedLoading) allLoaded.value = true;
          reload.value = false;
          items.value = data.items;
        } else if (loadingMore.value) {
          if (finishedLoading) allLoaded.value = true;
          loadingMore.value = false;
          items.value = [...items.value, ...data.items];
        } else if (items.value.length) {
          items.value = [...data.items, ...items.value];
        } else {
          if (finishedLoading) allLoaded.value = true;
          items.value = data.items;
        }
      } else {
        if (loadingMore.value && !search.searchLoading.value) {
          search.fetchNext();
          loadingMore.value = false;
        }
      }

      return items.value;
    };

    const queryKey = reactive([
      'columns',
      {
        id: props.resource.resourceId,
        highlight: props.resource.settings?.showSnippet,
      },
    ]);

    const queryOptions = reactive({
      refetchInterval: props.resource.refreshRate * 1000,
      enabled: isVisible,
    });

    const query = useQuery(queryKey, fetchColumn, queryOptions);

    const handleScroll = () => {
      const { scrollTop, offsetHeight, scrollHeight } = scrollElement.value;
      const scrollDistance = scrollTop + offsetHeight;
      if (
        !allLoaded.value &&
        !loadingMore.value &&
        !search.searchLoading.value &&
        scrollDistance > scrollHeight - 100
      ) {
        loadingMore.value = true;
        query.refetch.value();
      }
    };

    const resizeIsBig = computed(() => {
      const exists = !isUndefined(props.resource.settings?.isBig);
      return exists ? props.resource.settings?.isBig : false;
    });

    const handleResize = () => {
      store.dispatch('workspaces/updateResourceItem', {
        resourceId: props.resource.resourceId,
        settings: { isBig: resizeIsBig.value ? false : true },
      });
    };

    const handleReload = () => {
      reload.value = true;
      query.refetch.value();
    };

    const handleScrollTop = () => {
      scrollElement.value.scrollTop = 0;
    };

    onMounted(() => {
      scrollElement.value.addEventListener('scroll', handleScroll);
    });

    onBeforeUnmount(() => {
      scrollElement.value.removeEventListener('scroll', handleScroll);
    });

    const isEmpty = computed(() =>
      search.showSearch.value
        ? search.searchEmpty.value
        : !query.isLoading && !items.value.length,
    );

    return {
      NEWS_ARTICLE,
      CONTEXT_TYPES,
      root,
      anchor,
      renderItems,
      renderItemIds,
      allLoaded,
      loadingMore,
      resizeIsBig,
      handleResize,
      handleReload,
      handleScrollTop,
      isEmpty,
      ...query,
      ...search,
      ...useValidation(),
    };
  },
});

import { format } from 'date-fns';

import { ARTICLE_RELATIONSHIP } from '@/config/enums';

import searchParams from './searchParams';

export interface ListOptions {
  count?: number;
  offset?: number;
  from?: Date;
  days?: number;
  highlight?: boolean;
  searchColumn?: boolean;
  versions?: boolean;
  totalCount?: boolean;
  includeRelated?: number;
  orderBy?: string;
  toItem?: string;
  fromItem?: string;
  relation?: ARTICLE_RELATIONSHIP;
}

export default (options: ListOptions | undefined = undefined): string =>
  searchParams({
    expand: 'list',
    count: options?.count,
    offset: options?.offset,
    fromDate: options?.from && format(options?.from, 'yyyy-MM-dd'),
    numDays: options?.days,
    includeRelated: options?.includeRelated,
    highlightClass: options?.highlight && 'highlight',
    includeVersions: options?.versions && '1',
    includeTotalCounts: options?.totalCount && '1',
    relation: options?.relation,
    orderBy: options?.orderBy,
    toItem: options?.toItem,
    fromItem: options?.fromItem,
  });

import { pick } from 'lodash';

import mapEvents from '@/services/mapping/mapEvents';

import http from '../http';
import listOptions, { ListOptions } from '../listOptions';
import {
  Article,
  ArticleList,
  ArticleMail,
  ArticleMedia,
  ArticleRelated,
} from './article.types';
import { SearchQuery } from './search';

export interface Query {
  id: string;
  name: string;
  queryText?: string;
  queryTitle?: string;
  queryAuthor?: string;
  period: string;
  fromDate?: string;
  toDate?: string;
  sources?: string[];
  calendars?: string[];
  sourceKinds?: string[];
}

export interface StoreQuery {
  queryId?: string;
  name: string;
  queryText?: string;
  queryTitle?: string;
  queryAuthor?: string;
  period: string;
  fromDate?: string;
  toDate?: string;
  sources?: string[];
  calendars?: string[];
}

export interface UpdateName {
  resourceId: string;
  name: string;
}

const show = (id: string): Promise<Query> => {
  return http.get(`/queries/${id}`);
};

const items = async (
  id: string,
  options: ListOptions,
): Promise<ArticleList> => {
  const params = listOptions(options);
  const data = await http.get(`/queries/${id}/items${params}`);

  return {
    ...data,
    items: mapEvents(data.items),
  };
};

const article = (
  resourceId: string,
  id: string,
  options?: ListOptions,
): Promise<Article> => {
  const params = listOptions(options);
  if (options?.searchColumn) {
    return http.get(`/queries/${resourceId}/items/searchresult/${id}${params}`);
  } else {
    return http.get(`/queries/${resourceId}/items/${id}${params}`);
  }
};

const articleMedia = (
  resourceId: string,
  id: string,
): Promise<ArticleMedia[]> => {
  return http.get(`/queries/${resourceId}/items/${id}/media`);
};

const articleRelated = (
  resourceId: string,
  id: string,
  options: ListOptions,
): Promise<ArticleRelated> => {
  const params = listOptions(options);
  return http.get(`/queries/${resourceId}/items/${id}/related${params}`);
};

const articleMail = (item: ArticleMail): Promise<undefined> => {
  return http.post(`/queries/${item.resourceId}/items/${item.id}/mail`, {
    username: item.email,
    subject: item.subject,
    comment: item.message,
  });
};

const search = async (
  item: SearchQuery,
  options: ListOptions,
): Promise<ArticleList> => {
  const params = listOptions(options);
  const data = await http.post(
    `/queries/${item.id}/items/searchresult${params}`,
    item,
  );

  return {
    ...data,
    items: mapEvents(data.items),
  };
};

const store = (item: StoreQuery): Promise<{ id: string }> => {
  return http.post(
    '/queries',
    pick(item, [
      'name',
      'queryText',
      'queryTitle',
      'queryAuthor',
      'period',
      'fromDate',
      'toDate',
      'sources',
      'calendars',
    ]),
  );
};

const update = (item: StoreQuery): Promise<{ id: string }> => {
  return http.put(
    `/queries/${item.queryId}`,
    pick(item, [
      'name',
      'queryText',
      'queryTitle',
      'queryAuthor',
      'period',
      'fromDate',
      'toDate',
      'sources',
      'calendars',
    ]),
  );
};

const updateName = (item: UpdateName): Promise<undefined> => {
  return http.put(`/queries/${item.resourceId}/name`, pick(item, ['name']));
};

export default {
  show,
  items,
  article,
  articleMedia,
  articleRelated,
  articleMail,
  search,
  store,
  update,
  updateName,
};


import { format } from 'date-fns';
import { computed, defineComponent, Ref, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { DISPLAY_TYPES, SECTION_TYPES, SORT_TYPES } from '@/config/enums';
import useFeatures from '@/hooks/useFeatures';
import useMessage from '@/hooks/useMessage';
import useValidation from '@/hooks/useValidation';
import api from '@/services/api';
import { ArticleListItem } from '@/services/api/modules/article.types';
import { Query } from '@/services/api/modules/queries';

import SaveQuery from '../modals/SaveQuery.vue';
import Calendars from './Calendars.vue';
import Results from './Results.vue';
import Sources from './Sources.vue';

export default defineComponent({
  props: {
    query: {
      type: Object as () => Query,
      required: false,
    },
    modalForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    SaveQuery,
    Calendars,
    Results,
    Sources,
  },

  emits: ['done'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();

    const periodOptions = [
      { text: t('search.period-options.no-period'), value: '0' },
      { text: t('search.period-options.24-hours'), value: '1' },
      { text: t('search.period-options.7-days'), value: '7' },
      { text: t('search.period-options.31-days'), value: '31' },
      { text: t('search.period-options.select-period'), value: '-1' },
    ];

    const newsEnabled = computed(() => store.getters['workspaces/newsEnabled']);
    const calendarEnabled = computed(
      () => store.getters['workspaces/calendarEnabled'],
    );

    const { message, setMessage, clearMessage } = useMessage();
    const form = ref();
    const saveModal = ref();
    const accordion = ref();
    const loading = ref(false);

    const loadingMore = ref(false);

    const items: Ref<ArticleListItem[]> = ref([]);
    const count = ref(0);
    const showDescription = ref(false);
    const sourcesEl = ref();
    const calendarsEl = ref();
    const sources = ref();
    const calendars = ref();
    const type = ref(
      newsEnabled.value ? SECTION_TYPES.NEWS : SECTION_TYPES.CALENDAR,
    );
    const displayType = ref(DISPLAY_TYPES.COMPACT);
    const sortBy = ref(SORT_TYPES.DATE);
    const queryText = ref(
      route.params.query ? (route.params.query as string) : '',
    );
    const queryTitle = ref('');
    const queryAuthor = ref('');
    const period = ref(periodOptions[0]);
    const range = ref();

    const numResults = 30;

    const sourcesIds = computed(() => sourcesEl.value?.getSelectedIds());
    const calendarsIds = computed(() => calendarsEl.value?.getSelectedIds());

    const formatDate = (date: Date) => format(date, 'dd-MM-yyyy');

    const fromDate = computed(() =>
      range.value?.start ? format(range.value?.start, 'yyyy-MM-dd') : undefined,
    );

    const toDate = computed(() =>
      range.value?.end ? format(range.value.end, 'yyyy-MM-dd') : undefined,
    );

    const resetState = () => {
      items.value = [];
      count.value = 0;
      accordion.value = undefined;
    };

    const handleSubmit = async () => {
      clearMessage();
      resetState();
      fetchResults();
    };

    const loadMore = async () => {
      if (!loadingMore.value) {
        loadingMore.value = true;
        clearMessage();
        fetchResults();
      }
    };

    const fetchResults = async () => {
      loading.value = true;
      const [lastItem] = items.value.slice(-1);

      const endpoint =
        type.value === SECTION_TYPES.NEWS
          ? api.search.news
          : api.search.calendar;

      const defaultFrom =
        type.value === SECTION_TYPES.CALENDAR
          ? format(new Date(), 'yyyy-MM-dd')
          : undefined;

      const result = await endpoint(
        {
          queryText: queryText.value,
          queryTitle: queryTitle.value,
          queryAuthor: queryAuthor.value,
          sources: sourcesIds.value,
          calendars: calendarsIds.value,
          period: type.value === SECTION_TYPES.NEWS ? period.value.value : '-1',
          fromDate: fromDate.value || defaultFrom,
          toDate: toDate.value,
        },
        {
          count: numResults,
          highlight: displayType.value === DISPLAY_TYPES.SNIPPET,
          orderBy: sortBy.value === SORT_TYPES.RELEVANCE ? 'score' : 'time',
          fromItem: loadingMore.value ? lastItem?.id : undefined,
        },
      )
        .catch(setMessage())
        .finally(() => {
          loading.value = false;
        });

      if (result) {
        if (loadingMore.value) {
          items.value = [...items.value, ...result.items];
          loadingMore.value = false;
        } else {
          items.value = result.items;
          count.value = result.resultCount || 0;
          showDescription.value = displayType.value !== DISPLAY_TYPES.COMPACT;
        }
      }
    };

    const fillForm = (query: Query | undefined) => {
      if (query) {
        sources.value = query.sources;
        calendars.value = query.calendars;
        queryText.value = query.queryText || '';
        queryTitle.value = query.queryTitle || '';
        queryAuthor.value = query.queryAuthor || '';

        if (
          query.sourceKinds != null &&
          query.sourceKinds.includes('CALENDAR')
        ) {
          type.value = SECTION_TYPES.CALENDAR;
        } else {
          type.value = SECTION_TYPES.NEWS;
        }

        period.value =
          periodOptions.find((option) => option.value === query.period) ||
          periodOptions[0];

        if (query.fromDate && query.fromDate) {
          range.value = {
            start: new Date(query.fromDate),
            end: new Date(query.fromDate),
          };
        }
      }
    };

    watch(type, resetState);
    watch(() => props.query, fillForm);

    const handleDone = () => emit('done');
    const showSave = () => saveModal.value?.show();

    return {
      SORT_TYPES,
      DISPLAY_TYPES,
      SECTION_TYPES,
      periodOptions,
      newsEnabled,
      calendarEnabled,
      message,
      form,
      loading,
      saveModal,
      sources,
      sourcesEl,
      calendars,
      calendarsEl,
      accordion,
      items,
      count,
      showDescription,
      type,
      displayType,
      sortBy,
      queryText,
      queryTitle,
      queryAuthor,
      period,
      range,
      formatDate,
      fromDate,
      toDate,
      sourcesIds,
      calendarsIds,
      handleSubmit,
      handleDone,
      loadMore,
      loadingMore,
      showSave,
      ...useFeatures(),
      ...useValidation(),
    };
  },
});

import emailValidator from 'email-validator';
import { useI18n } from 'vue-i18n';

interface Validation {
  required(value: string): string | undefined;
  equal(title: string, to: string): (value: string) => string | undefined;
  validEmail(value: string): string | undefined;
  validPhone(value: string): string | undefined;
  validPassword(value: string): string | undefined;
}

const CAPITAL_REGEX = /[A-Z]/;
const LOWER_REGEX = /[a-z]/;
const DIGIT_REGEX = /\d/;
const SYMBOL_REGEX = /[!@#$%&?/]/;

export default (): Validation => {
  const { t } = useI18n();

  const required = (value: string): string | undefined => {
    return value ? undefined : t('validation.required');
  };

  const equal =
    (title: string, to: string) =>
    (value: string): string | undefined => {
      return to === value ? undefined : t('validation.equal', { title });
    };

  const validEmail = (value: string): string | undefined => {
    return emailValidator.validate(value) ? undefined : t('validation.email');
  };

  const validPhone = (value: string): string | undefined => {
    return /\+316[\d]{8}/.test(value) || value == ''
      ? undefined
      : t('validation.phone');
  };

  const validPassword = (value: string): string | undefined => {
    if (!value) return undefined;

    if (
      value.length < 9 ||
      !CAPITAL_REGEX.test(value) ||
      !LOWER_REGEX.test(value) ||
      !DIGIT_REGEX.test(value) ||
      !SYMBOL_REGEX.test(value)
    ) {
      return t('validation.password');
    }

    return undefined;
  };

  return {
    required,
    equal,
    validEmail,
    validPhone,
    validPassword,
  };
};
